import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientsComponent } from './clients/clients.component';
import { LmClientsRoutingModule } from './lm-clients-routing.module';
import { LmFiltersClientsComponent } from './sections/lm-filters-clients/lm-filters-clients.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClientDetailsComponent } from './client-details/client-details.component';
import { LmClientBasicDataComponent } from './sections/lm-client-basic-data/lm-client-basic-data.component';
import { LmUpdateClientDataComponent } from './sections/lm-update-client-data/lm-update-client-data.component';
import { LmClientRequestsComponent } from './sections/lm-client-requests/lm-client-requests.component';
import { LmFiltersClientDetailsComponent } from './sections/lm-filters-client-details/lm-filters-client-details.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { LmClientOperationsComponent } from './sections/lm-client-operations/lm-client-operations.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [
        ClientsComponent,
        ClientDetailsComponent,
        LmFiltersClientsComponent,
        LmClientBasicDataComponent,
        LmUpdateClientDataComponent,
        LmFiltersClientDetailsComponent,
        LmClientRequestsComponent,
        LmClientOperationsComponent
    ],
    imports: [
        CommonModule,
        MatTableModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatDatepickerModule,
        MatCardModule,
        MatNativeDateModule,
        LmClientsRoutingModule,
        TranslateModule,
		MatAutocompleteModule,
        MatSelectModule,
        MatMenuModule,
        MatTabsModule,
        MatCheckboxModule,
        MatSnackBarModule,
        SharedModule,
        NgMultiSelectDropDownModule,
        MatTooltipModule
    ],
    exports: [ClientsComponent],
    providers: [],
})
export class LmClientsModule {
}