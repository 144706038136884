import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MaintenanceService } from '../../../core/services/mant.service';
import { Utilities } from '../../../../app/shared/utilities/utilities';
import { ModalSepaComponent } from '../../modals/modal-sepa/modal-sepa.component';
import { AuthenticationService } from '../../../core/services/auth.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-lm-transfer-sepa',
  templateUrl: './lm-transfer-sepa.component.html',
  styleUrls: ['./lm-transfer-sepa.component.scss', '../../utils/transfers.scss']
})
export class LmTransferSepaComponent implements OnInit, OnDestroy {
  _translateSubscription: Subscription;
  _messages: { [key: string]: string };
  form: FormGroup;
  message: string;
  isError: boolean = false;
  isDisabled: boolean = false;
  submitted: boolean = false;
  newTotalCost: number;
  newTotalPaid: number;
  newAmmount: number;
  isUserLeroy: boolean;
  classButton: string;
  operation: any;
  lastValidAmountSEPA: string = "";
  maxAmmount: number = 99999999.99;

  dialogRef: MatDialogRef<ModalSepaComponent>;

  constructor(
    private _mant: MaintenanceService,
    private _router: Router,
    public dialog: MatDialog,
    private _translateService: TranslateService,
    private _auth: AuthenticationService
  ) {
    this._translateSubscription = this._translateService
      .get('lm-transfer-sepa.logic')
      .subscribe((data: { [key: string]: string }) => {
        this._messages = data;
      });
  }
  ngOnDestroy(): void {
    this._translateSubscription.unsubscribe();
  }

  ngOnInit() {
    this.form = new FormGroup({
      totalCost: new FormControl(""),
      totalPaid: new FormControl(""),
      amountSEPA: new FormControl("", [Validators.required, Validators.pattern(/(^[0-9]{1,6}$)|(^[0-9]{1,6}\,\d{0,2}$)/)]),
      operationNumber: new FormControl(null, Validators.required),
      professionalEmail: new FormControl('', [Validators.required, Validators.email]),
      operationConcept: new FormControl(null, Validators.required),
      completed: new FormControl(false),
      endTime: new FormControl(false)
    });
    this.isUserLeroy = Utilities.isUserHogami();
    this.classButton = 'form__button';

    this._auth.isNewTokenExpired();
  }
  get f() { return this.form.controls; }
  get amountSEPA() { return this.form.controls.amountSEPA; }
  get totalCost() { return this.form.controls.totalCost; }
  get totalPaid() { return this.form.controls.totalPaid; }
  get operationNumber() { return this.form.controls.operationNumber; }

  transferSepa() {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    this.message = "";
    const {
      totalCost,
      totalPaid,
      amountSEPA,
      operationNumber,
      professionalEmail,
      operationConcept,
      completed = false,
      endTime = false,
    } = this.form.value;

    const errorMessages = {
      totalCost: 'enter_positive_total_cost',
      totalPaid: 'enter_positive_total_paid',
      amountSEPA: 'enter_positive_amount_SEPA',
      operationNumber: 'enter_valid_operation',
    };

    for (const field in errorMessages) {
      if (!this.checkAmmount(this.form.controls[field].value)) {
        this.form.controls[field].setValue('');
        this.isError = true;
        this.message = this._messages[errorMessages[field]];
        return;
      }
    }

    if (!this.operation) {
      this.isError = true;
      this.message = this._messages['operation_id_no_valid'];
      return;
    }

    const emailProf = professionalEmail.toLowerCase();

    const bodySepa = {
      totalCost,
      totalPaid,
      amount: amountSEPA,
      operationNumber,
      emailProf,
      operationConcept,
      completed,
      endTime
    };
    this.openDialog(bodySepa);
  }

  proccessTransferSepa(bodySepa) {
    const { totalCost, totalPaid, amount, operationNumber, emailProf, operationConcept, completed, endTime } = bodySepa;

    const processedTotalCost = (parseFloat(this.proccesAmmounts(totalCost.trim())) * 100).toFixed();
    const processedTotalPaid = (parseFloat(this.proccesAmmounts(totalPaid.trim())) * 100).toFixed();
    const processedAmount = (parseFloat(this.proccesAmmounts(amount.trim())) * 100).toFixed();

    this._mant.transferSepa({
      totalCost: processedTotalCost,
      totalPaid: processedTotalPaid,
      amount: processedAmount,
      operationNumber,
      professionalEmail: emailProf,
      operationConcept,
      completed,
      endTime
    }).subscribe({
      next: (response) => {
        this.submitted = false;
        if (response.code === "200") {
          if (response.data.statusCode != "0") {
            this.isDisabled = false;
            this.isError = true;
            this.message = response.data.description;
          } else {
            this.message = this._messages['transfer_successfully_completed'];
            this.isError = false;
            this.isDisabled = false;
          }
          this.dialogRef.componentInstance.myf({ progress: false }, { transferDiv: true }, { transferMsg: this.message });
        } else {
          this.message = `${this._messages['error_operations']} ${operationNumber} - ${response.data.description}`;
          this.isError = true;
          this.isDisabled = false;
          this.dialogRef.componentInstance.myf({ progress: false }, { transferDiv: false }, { transferMsg: this.message });
        }
      },
      error: (error) => {
        if (error.error.code.includes("SPSE-409-9")) {
          this.isDisabled = false;
          this.isError = true;
          this.message = error.error.message;
          this.dialogRef.componentInstance.myf({ progress: false }, { transferDiv: false }, { transferMsg: this.message });
        } else if (error.error.message.includes("SPRO-400-18")) {
          this.isDisabled = false;
          this.isError = true;
          this.message = this._messages['prof_email_not_registred'];
          this.dialogRef.componentInstance.myf({ progress: false }, { transferDiv: false }, { transferMsg: this.message });
        } else {
          this.isDisabled = false;
          this.isError = true;
          this.message = this._messages['operation_or_practitioner_incorrect'];
          this.dialogRef.componentInstance.myf({ progress: false }, { transferDiv: false }, { transferMsg: this.message });
        }
      }
    });
  }


  openDialog(bodySepa) {
    const ammountValid = (parseFloat(this.proccesAmmounts(bodySepa['amount'].trim())) * 100).toFixed();
    if (parseInt(ammountValid) > this.maxAmmount) {
      this.isError = true
      this.message = this._messages['amount_exceeds_maximum_allowed'];
      return;
    }

    this.dialogRef = this.dialog.open(ModalSepaComponent, {
      width: '600px',
      height: 'auto',
      disableClose: true,
      data: {
        operation: this.operation,
        bodySepa: bodySepa,
        progress: false,
        transferDiv: false,
        transferMsg: ''
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      console.debug('The dialog was closed');
      this.message = '';
    });

    this.dialogRef.componentInstance.submitClicked.subscribe(result => {
      if (result === 'submit') {
        this.proccessTransferSepa(bodySepa);
      } else {
        this.dialogRef.close();
        window.location.reload();
      }
    });
  }

  goBack() {
    this._router.navigate(["/home"]);
  }

  onChangeOperationNumber(e) {
    const key = e.key;
    if (key < '0' || key > '9') {
      e.preventDefault();
    }
  }

  changeValue(e, inputField) {
    const value = e.target.value;
    if (!value) {
      return;
    }
    if (e.target.name === "amountSEPA") {
      const regex = /(^[0-9]{1,6}$)|(^[0-9]{1,6}\,\d{0,2}$)/
      if (regex.test(value)) {
        inputField.setErrors({ 'pattern': false });
        inputField.setValue(value);
        this.lastValidAmountSEPA = value
      } else {
        inputField.setErrors({ 'pattern': true });
        inputField.setValue(this.lastValidAmountSEPA)
      }
      return
    }
    const reg = /((^[0-9]{1,}$)|\d+(\,\d{0,2})$)/
    if (reg.test(value)) {
      inputField.setValue(value)
    } else {
      inputField.setValue(value.substring(0, value.length - 1))
    }
  }

  onBlur(event, inputField) {
    const value = event.target.value
    if (value && value.slice(-1) == ",") {
      inputField.setValue(value.substring(0, value.length - 1))
    }
  }

  onBlurOperation(event, inputField) {
    this.operation = null;
    const value = event.target.value
    if (value && value.slice(-1) == ",") {
      inputField.setValue(value.substring(0, value.length - 1))
    }
    if (value != '') {
      this.classButton = 'form__button_back';
      this.isDisabled = true;
      this.isError = false
      this.message = '';

      this._mant.findOperation(value).subscribe({
        next: (res) => {
          this.operation = res.data;
          this.classButton = 'form__button';
          this.isDisabled = false;
          this.isError = false
        },
        error: (error) => {
          this.operation = null;
          this.classButton = 'form__button';
          this.isDisabled = false;
          this.isError = true
          this.message = this._messages['operation_not_found'];
          if (error.error.code === "SBACK-401-2") {
            localStorage.removeItem('token');
          }
        }
      });
    }
  }

  proccesAmmounts(number: string): string {
    if (!number || number === "") {
      return "0";
    }

    return number.includes(",") ? number.replace(/,/g, ".") : number;
  }


  checkAmmount(amount: string): boolean {
    const t = (parseFloat(this.proccesAmmounts(amount)) * 100).toFixed();
    return parseFloat(t) >= 0;
  }

}
