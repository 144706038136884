import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LmProfessionalsRoutingModule } from './lm-professionals-routing.module';
import { ProfessionalsComponent } from './professionals/professionals.component';
import { ProfessionalDetailsComponent } from './professional-details/professional-details.component';
import { LmFiltersProfessionalsComponent } from './sections/lm-filters-professionals/lm-filters-professionals.component';
import { RatingDialogComponent } from './sections/rating-dialog/rating-dialog.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { LmProfessionalRequestsComponent } from './sections/lm-professional-requests/lm-professional-requests.component';
import { LmProfessionalOperationsComponent } from './sections/lm-professional-operations/lm-professional-operations.component';
import { LmProfessionalServicesComponent } from './sections/lm-professional-services/lm-professional-services.component';
import { LmProfessionalServiceCoverageDetailsComponent } from './sections/lm-professional-service-coverage-details/lm-professional-service-coverage-details.component';
import { LmFiltersProfessionalDetailsComponent } from './sections/lm-filters-professional-details/lm-filters-professional-details.component';
import { LmProfessionalBasicDataComponent } from './sections/lm-professional-basic-data/lm-professional-basic-data.component';
import { LmProfessionalStatusComponent } from './sections/lm-professional-status/lm-professional-status.component'
import { LmRatingComponent } from './sections/lm-rating/lm-rating.component';
import { ProfessionalStatusDialogComponent } from './sections/professional-status-dialog/professional-status-dialog.component';
import { RequestService } from '../lm-transfers/services/request.service';
import { SharedModule } from '../../shared/shared.module';
import { LmProfessionalServicesHierarchyComponent } from './sections/lm-professional-services-hierarchy/lm-professional-services-hierarchy.component';
import { LmTreeDataHierarchyComponent } from './sections/lm-professional-services-hierarchy/section/lm-tree-data-hierarchy.component';
import { RoundUtilPipe } from '../../shared/pipes/round-util.pipe';
import { LmProfessionalDocumentsComponent } from './sections/lm-professional-documents/lm-professional-documents.component';
import { ImageCropperModule } from '../../../app/components/image-cropper/image-cropper.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDividerModule } from '@angular/material/divider';
import { LmProfessionalRegisterComponent } from './lm-professional-register/lm-professional-register.component';
import { DndDirective } from 'src/app/shared/utilities/dnd.directive';

@NgModule({
  declarations: [
    ProfessionalsComponent,
    ProfessionalDetailsComponent,
    RatingDialogComponent,
    LmFiltersProfessionalsComponent,
    LmProfessionalRequestsComponent,
    LmProfessionalOperationsComponent,
    LmProfessionalServicesComponent,
	LmProfessionalServicesHierarchyComponent,
    LmProfessionalServiceCoverageDetailsComponent,
	LmProfessionalDocumentsComponent,
    LmFiltersProfessionalDetailsComponent,
    LmProfessionalBasicDataComponent,
	LmProfessionalStatusComponent,
    LmRatingComponent,
	ProfessionalStatusDialogComponent,
	LmProfessionalRegisterComponent,
	LmTreeDataHierarchyComponent,
	RoundUtilPipe,
	DndDirective
    ],
  imports: [
    CommonModule,
    MatTableModule,
	MatProgressSpinnerModule,
	MatProgressBarModule,
	MatToolbarModule,
	MatTooltipModule,
	MatDialogModule,
	MatInputModule,
	MatDatepickerModule,
	MatCardModule,
	MatIconModule,
	MatNativeDateModule,
	MatFormFieldModule,
	MatButtonModule,
	FormsModule,
	ReactiveFormsModule,
	MatPaginatorModule,
	MatCheckboxModule,
	MatRadioModule,
	MatMenuModule,
	MatOptionModule,
	MatSelectModule,
	MatExpansionModule,
  	LmProfessionalsRoutingModule,
	NgMultiSelectDropDownModule,
	MatTabsModule,
	SharedModule,
	MatTreeModule,
	MatGridListModule,
	MatSnackBarModule,
	MatDividerModule,
	ImageCropperModule,
	PdfViewerModule
  ],
  exports: [ProfessionalsComponent],
  providers: [RequestService, RoundUtilPipe],
})
export class LmProfessionalsModule { }
