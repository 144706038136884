<div class="content">
    <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{'lm-journey-details.sections.lm-journey-status.title' | translate}}
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <form action="" [formGroup]="form" (ngSubmit)="submitForm()">
            <div class="filters_form_group">
                <div>
                    <mat-form-field>
                        <mat-label>{{'lm-journey-details.sections.lm-journey-status.form.newStatus' | translate}}</mat-label>
                        <mat-select formControlName="newStatus" name="newStatus">
                            <mat-option *ngFor="let requestStatusEnd of requestStatusEnds" [value]="requestStatusEnd.id" (click)="selectStatus(requestStatusEnd.name)">{{ requestStatusEnd.name }}</mat-option>
                          </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="filters_form_group">
                <div *ngIf="requestRequirements">
                        <div *ngFor="let prop of requirementProperties">
                            <div *ngIf="prop.typeInput === 'radio'">
                                <mat-radio-group [formControlName]="prop.nameField">
                                    <mat-radio-button value="1" [name]="prop.nameField">{{ prop.requestRequirementPropertyDescriptions[0].description }}</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div *ngIf="prop.typeInput === 'textArea'">
                                <mat-form-field>
                                    <mat-label>{{ prop.requestRequirementPropertyDescriptions[0].description }}</mat-label>
                                    <textarea matInput [placeholder]="prop.requestRequirementPropertyDescriptions[0].description" type="text" 
                                    [formControlName]="prop.nameField" [name]="prop.nameField" rows="4"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                </div>
            </div>
            <div class="filters_form_group">
                <div *ngIf="rejectionMotives">
                    <mat-form-field>
                        <mat-label>{{'lm-journey-details.sections.lm-journey-status.form.cancelationMotive' | translate}}</mat-label>
                        <mat-select formControlName="rejectionMotiveId" name="rejectionMotiveId">
                            <mat-option *ngFor="let rejectionMotive of rejectionMotives" [value]="rejectionMotive.id" (click)="selectMotive(rejectionMotive.motive)">{{ rejectionMotive.motive }}</mat-option>
                          </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="button-row">
                <button mat-raised-button color="primary" type="submit" class="button_submit" [disabled]="!formIsValid()"> {{'lm-journey-details.sections.lm-journey-status.form.save' | translate}} </button>
            </div>
        </form>
        <mat-progress-bar *ngIf="loading" mode="indeterminate" value="140"></mat-progress-bar>
    </mat-expansion-panel>
</div>