import { Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IClientFilterData, IClientType, InterfaceCoutriesRegex } from '../../models/lm-clients.model';
import { CountryPhone, InterfaceCountries } from 'src/app/shared/utilities/interface-countries';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ClientService } from '../../services/client.service';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { TransferService } from 'src/app/pages/lm-transfers/services/transfers.service';

@Component({
  selector: 'app-lm-filters-clients',
  templateUrl: './lm-filters-clients.component.html',
  styleUrls: ['./lm-filters-clients.component.scss', '../../../utils/form-search.scss', '../../../utils/dropdown.scss']
})
export class LmFiltersClientsComponent implements OnInit {
  @ViewChild(MatAutocomplete) autocomplete: MatAutocomplete;

  clientTypes: IClientType[];
  options: InterfaceCountries[] = [];
  countrySelected: InterfaceCountries;
  filteredOptions: Observable<InterfaceCountries[]>;
  defaultCountry: string = '+34';
  clientFilterForm: FormGroup;
  clientFilterData: IClientFilterData;
  countryPhones: CountryPhone[];
  documentsTypes: InterfaceCoutriesRegex[] = [];

  @Input() loading: boolean = false;
  @Output() filtersApply: EventEmitter<IClientFilterData> = new EventEmitter<IClientFilterData>();

  constructor(
    private _clientService: ClientService,
    private _transferService: TransferService,
    private renderer: Renderer2) { }

  ngOnInit() {
    this.retrieveAllServiceTypes();
    this.createForm();
    this.loadCountries();
    this.retrieveClientDocumentTypes();
  }

  /**
   * Create form to filter
   */
  createForm(): void {
    this.clientFilterForm = new FormGroup({
      nameAndLastNameClient: new FormControl(null),
      countryCode: new FormControl(null),
      phoneClient: new FormControl(null),
      email: new FormControl(null),
      documentNumber: new FormControl(null),
      clientTypeId: new FormControl(null),
      createDateStart: new FormControl(null),
      createDateEnd: new FormControl(null),
      documentType: new FormControl(null)
    });
  }

  /**
   * Send Submit
   */
  submitForm(): void {
    this.sendFilters();
  }

  /**
   * Send filter to search
   */
  sendFilters(): void {
    this.clientFilterData = {
      nameAndLastNameClient: this.clientFilterForm.controls.nameAndLastNameClient.value,
      countryCode: this.clientFilterForm.controls.countryCode.value,
      phoneClient: this.clientFilterForm.controls.phoneClient.value,
      email: this.clientFilterForm.controls.email.value,
      documentNumber: this.clientFilterForm.controls.documentNumber.value,
      clientTypeId: this.clientFilterForm.controls.clientTypeId.value,
      createDateStart: this.clientFilterForm.controls.createDateStart.value ? Utilities.getDate(this.clientFilterForm.controls.createDateStart.value) : null,
      createDateEnd: this.clientFilterForm.controls.createDateEnd.value ? Utilities.getDate(this.clientFilterForm.controls.createDateEnd.value) : null,
      documentType : this.clientFilterForm.controls.documentType.value
    };
    this.filtersApply.emit(this.clientFilterData);
  }

  /**
   * Reset form
   */
  resetFilters(): void {
    this.clientFilterForm.reset();
  }

  /**
   * Retrieve Countries
   */
  loadCountries(): void {
    this._transferService.getCountries().subscribe(res => {
      this.countryPhones = res.data;
      this.countryPhones.forEach(item => {
        let option: InterfaceCountries = {
          name: item.countryRest.countryName,
          shortName: '',
          countryCode: item.countryCode,
          flag: item.countryRest.countryIconKey,
          minNumberDigits: item.countryMinNumberDigits,
          maxNumberDigits: item.countryMaxNumberDigits
        }
        this.options.push(option);
      });
      this.clientFilterForm.controls['countryCode'].setValue(this.defaultCountry);
      this.countrySelected = this.findCountryByCode(this.defaultCountry);
    })
  }

  /**
   * Retrieve All Services Types
   */
  retrieveAllServiceTypes(): void {
    this._clientService.retrieveAllClientTypes().then(res => {
      this.clientTypes = res['data'].map(type => {
        switch (type.name) {
          case 'PART':
            return { ...type, name: 'PARTICULAR' };
          case 'FREE':
            return { ...type, name: 'FREELANCE' };
          case 'COMP':
            return { ...type, name: 'COMPANY' };
          default:
            return type;
        }
      });
    });
  }


  /**
   * Display in input Autocomplete
   * @param country 
   * @returns 
   */
  displayFn(country: InterfaceCountries): any {
    return country;
  }

  /**
   * Selected country
   * @param item 
   */
  getOption(item) {
    this.countrySelected = this.findCountryByCode(item);
    this.renderer.selectRootElement('#phoneClient').focus();
  }

  /**
   * Find item by value
   * @param name
   */
  private _filter(name: string): InterfaceCountries[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(option =>
      option.name.toLowerCase().includes(filterValue) || option.countryCode.includes(filterValue)
    );
  }


  /**
   * Start Autocomplete
   */
  startAutocomplete() {
    this.filteredOptions = this.clientFilterForm.controls['countryCode'].valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value.name;
        return name ? this._filter(name as string) : this.options.slice();
      }),
    );
  }

  /**
   * Find country by code
   * @param countryCode
   */
  findCountryByCode(countryCode: string): InterfaceCountries {
    return this.options.find(item => item['countryCode'] === countryCode);
  }

  /**
   * Blur Inpu Country
   * @param event 
   */
  blurCountry(event) {
    let value = event.target.value;
    let country;
    if (!this.autocomplete.isOpen) {
      country = this.findCountryByCode(value);
      if (country) {
        this.countrySelected = country;
      }
      this.clientFilterForm.controls['countryCode'].setValue(this.countrySelected.countryCode);
    }
  }

  /**
   * Retrieve Document Types
   */
  retrieveClientDocumentTypes() {
    this._clientService.retrieveDocumentTypes()
    .then(res => {
      this.documentsTypes = res['data'];
    });
  }

  /**
   * Click in input autocomplete
   */
  clickEvent() {
    this.startAutocomplete();
  }

}
