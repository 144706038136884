import { Component, OnInit } from "@angular/core";
import { ProfessionalService } from "../services/professional.service";
import { Utilities } from "src/app/shared/utilities/utilities";
import * as FileSaver from 'file-saver';
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subscription, catchError, finalize, tap, throwError } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: 'app-professional-register',
    templateUrl: './lm-professional-register.component.html',
    styleUrls: ['../../utils/form-search.scss', './lm-professional-register.component.scss', '../../utils/dropdown.scss']
})
export class LmProfessionalRegisterComponent implements OnInit {

    _translateSubscription: Subscription;

    type: string;

    fileSelected: File;
    informationIsValid: boolean;
    professionalList: any[];
    errorValidation: boolean;
    validationError: any;
    isProgress: boolean;
    isProgressRegistration: boolean;
    isResultSuccesfully: boolean;
    errorRegistration: boolean;
    registrationError: any;
    registrationMsg: string;
    fileNotValid: string;
    okSnack: string;

    constructor(
        private _profesionalService: ProfessionalService,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService,
        private route: ActivatedRoute) {
        this._translateSubscription = this._translateService.get("lm-professional.lm-professional-register")
            .subscribe((result: { [key: string]: string }) => {
                this.fileNotValid = result['errors']['file_not_valid'];
                this.okSnack = result['ok'];
            });
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.type = params['type'];
        });
    }

    /**
   * on file drop handler
   */
    onFileDropped($event: FileList): void {
        if (!this.fileSelected) {
            const validFileTypes = new Set(['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']);
            const invalidFiles: File[] = Array.from($event).filter(file => !validFileTypes.has(file.type));

            if (invalidFiles.length > 0) {
                this._snackBar.open(this.fileNotValid, this.okSnack, { duration: 1500 });
            } else {
                this.fileSelected = $event[0];
            }
        }
    }


    /**
   * handle file from browsing
   */
    fileBrowseHandler(files): void {
        this.onFileDropped(files);
    }

    /**
     * Download template
     */
    async downloadTemplate(): Promise<void> {
        try {
            const res = await this._profesionalService.generateTemplate().toPromise();
            const blob = new Blob(
                [
                    Utilities.base64toBlob(res['data']['excel'], 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
                ], {}
            );
            FileSaver.saveAs(
                blob,
                'TemplateProfesionales_'.concat(Utilities.getDateActual().concat('.xls'))
            );
        } catch (error) {
            this._snackBar.open('Error al descargar la plantilla.', 'OK', { duration: 1500 });
        }
    }

    /**
     * Delete File Selected
     */
    deleteFileSelected(): void {
        this.informationIsValid = false;
        this.fileSelected = null;
    }

    /**
     * Validate template before upload data
     */
    validateTemplate(): void {
        this.isProgress = true;
        this.errorValidation = false;
        const formData = new FormData();
        formData.append('file', this.fileSelected);
        formData.append('processName', this.type === 'ADD' ? 'ADD_PROFESSIONAL' : 'MODIFY_SERVICE_PRO');

        this._profesionalService.validateTemplate(formData).pipe(
            tap(res => {
                if (res['code'] === '200') {
                    this.informationIsValid = true;
                    this.professionalList = res['data'];
                    this.errorValidation = false;
                }
            }),
            catchError(error => {
                this.informationIsValid = false;
                this.errorValidation = true;
                this.validationError = error['error'];
                return throwError(error);
            }),
            finalize(() => {
                this.isProgress = false;
                this.errorValidation = true;
            })
        ).subscribe();
    }

    /**
     * Register professionals
     */
    registerProfessionals(): void {
        this.isProgressRegistration = true;
        const request$ = this.type === 'ADD' ? 
            this._profesionalService.registerProfessionals(this.professionalList) : 
            this._profesionalService.updateProfessionals(this.professionalList);
        
        request$.pipe(
            tap(res => {
                if (res['code'] === '200') {
                    this.isResultSuccesfully = true;
                    if (this.type === 'ADD') {
                        const blob = Utilities.base64toBlob(res['data'], 'text/csv');
                        const fileName = `RESUMEN_PROFESIONALES_${Utilities.getDateActual()}.txt`;
                        FileSaver.saveAs(blob, fileName);
                        this.registrationMsg = Utilities.decodeTextBase64(res['data']);
                    }
                }
                this.isProgressRegistration = false;
                this.errorRegistration = false;
            }),
            catchError(error => {
                this.isResultSuccesfully = false;
                this.isProgressRegistration = false;
                this.errorRegistration = true;
                this.registrationError = error['error'];
                throw error;
            })
        ).subscribe();
    }

}