import { TranslateService } from '@ngx-translate/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Campaigns } from 'src/app/pages/lm-transfers/models/lm-transfers.model';
import { TransferService } from 'src/app/pages/lm-transfers/services/transfers.service';
import { Utilities } from '../../../../shared/utilities/utilities';
import { Router } from '@angular/router';

@Component({
  selector: 'app-lm-filters-professionals',
  templateUrl: './lm-filters-professionals.component.html',
  styleUrls: ['../../../utils/form-search.scss', './lm-filters-professionals.component.scss', '../../../utils/dropdown.scss']
})
export class LmFiltersProfessionalsComponent implements OnInit {

  form: FormGroup;
  isUserLeroy: boolean;
  campaigns: Campaigns[] = [];
  dropdownSettingCampaigns = {};

  @Input() loading: boolean = false;
  @Output() filtersApply: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private _transferService: TransferService,
    private _translateService: TranslateService,
    private router: Router
  ) { }

  ngOnInit() {
    this.createForm();
    this.isUserLeroy = Utilities.isUserHogami();
    this.loadCampaigns();
    this.loadDropdownCampaigns();
  }

  createForm() {
    this.form = new FormGroup({
      createDateStart: new FormControl(''),
      createDateEnd: new FormControl(''),
      nameAndLastNameProfessional: new FormControl(''),
      email: new FormControl(''),
      phoneProfessional: new FormControl(''),
      personType: new FormControl(''),
      documentNumber: new FormControl(''),
      documentType: new FormControl(''),
      campaignSelected: new FormControl(''),
      ibanNumber: new FormControl('')
    });
  }

  submitForm() {
    this.sendFilters();
  }

  resetFilters() {
    this.form.reset();
  }

  sendFilters() {
    const infoFilters = {
      createDateStart: (this.form.controls['createDateStart'].value == null || this.form.controls['createDateStart'].value == "") ? null : Utilities.getDate(this.form.controls['createDateStart'].value),
      createDateEnd: (this.form.controls['createDateEnd'].value == null || this.form.controls['createDateEnd'].value == "") ? null : Utilities.getDate(this.form.controls['createDateEnd'].value),
      nameAndLastNameProfessional: (this.form.controls['nameAndLastNameProfessional'].value == null || this.form.controls['nameAndLastNameProfessional'].value == "") ? null : this.form.controls['nameAndLastNameProfessional'].value.trim(),
      email: (this.form.controls['email'].value == null || this.form.controls['email'].value == "") ? null : this.form.controls['email'].value.trim(),
      personType: (this.form.controls['personType'].value == null || this.form.controls['personType'].value == "") ? null : this.form.controls['personType'].value.trim(),
      documentNumber: (this.form.controls['documentNumber'].value == null || this.form.controls['documentNumber'].value == "") ? null : this.form.controls['documentNumber'].value.trim(),
      phoneProfessional: (this.form.controls['phoneProfessional'].value == null || this.form.controls['phoneProfessional'].value == "") ? null : this.form.controls['phoneProfessional'].value.trim(),
      documentType: (this.form.controls['documentType'].value == null || this.form.controls['documentType'].value == "") ? null : this.form.controls['documentType'].value.trim(),
      campaigns: (this.form.controls['campaignSelected'].value == null || this.form.controls['campaignSelected'].value == "") ? [] : this.proccessCampaigns(this.form.controls['campaignSelected'].value),
      ibanNumber: (this.form.controls['ibanNumber'].value == null || this.form.controls['ibanNumber'].value == "") ? null : this.form.controls['ibanNumber'].value.trim(),
    }
    this.filtersApply.emit(infoFilters);
  }

  /**
   * This function processes the array of campaigns to construct an array of integers.
   * @param arrayCampaign 
   */
  proccessCampaigns(arrayCampaign: any) {
    let finalCampaigns = [];
    arrayCampaign.forEach(element => {
      finalCampaigns.push(element.id);
    });
    return finalCampaigns;
  }

  /**
   * This function processes the array of stores to construct an array of integers.
   * @param arrayCampaign 
   */
  proccessStores(arrayStores: any) {
    let finalStores = [];
    arrayStores.forEach(element => {
      finalStores.push(element.id);
    });
    return finalStores;
  }

  loadCampaigns() {
    this._transferService.getAllCampaigns().subscribe(res => {
      this.campaigns = res.data;
      this.campaigns.push({
        id: 0,
        descriptionCampaign: '',
        codeCampaign: 'WEB'
      });
      this.campaigns.sort((a, b) => a.id.toString().localeCompare(b.id.toString()));
    });
  }

  /**
   * This function loads the configuration of the states dropdown.
   */
  loadDropdownCampaigns() {
    const dialogText = this._translateService.instant('lm-professional.lm-filters-professional.text');
    this.dropdownSettingCampaigns = {
      singleSelection: false,
      idField: 'id',
      textField: 'codeCampaign',
      selectAllText: dialogText.selectAllText,
      unSelectAllText: dialogText.unSelectAllText,
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
  }

  /**
   * Go the register page
   */
  goRegister(): void {
    this.router.navigate(['professionals/professional/template'], { queryParams: { type: 'ADD' } });
  }

  /**
   * Go the edit page
   */
  goEdit(): void {
    this.router.navigate(['professionals/professional/template'], { queryParams: { type: 'EDIT' } });
  }

}
