import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as FileSaver from 'file-saver';
import { BillingService } from '../services/billing.service';
import { Utilities } from '../../../shared/utilities/utilities';
import { CouponDialogComponent } from '../sections/coupon-dialog/coupon-dialog.component';
import { CouponVersion } from 'src/app/pages/lm-transfers/models/lm-transfers.model';
import { TransferService } from '../services/transfers.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['../../utils/mat-table.scss', './billing.component.scss'],
})
export class BillingComponent implements OnInit, OnDestroy {
  _translationSubscription: Subscription;
  _messages: { [key: string]: string };
  data: any = [];
  page: number = 0;
  totalElements: number = 0;
  pageSize: number = 10;
  loading: boolean = false;
  loadExcel: boolean = false;
  filters: any = {};
  buttonShowMore: boolean;
  stringShowMore: string = 'Ver todos';
  displayedColumns: string[] = [
    'id',
    'operationStatus',
    'isExternal',
    'professionalDetails',
    'entTime',
    'movements',
    'region',
    'operationOfferedServices',
    'paycometAssociate',
    'paycometOperationName',
    'paycometAmount',
    'transferType',
    'campaigns',
    'storeName',
    'comercialId',
    'fill',
  ];

  couponVersion: CouponVersion;
  dialogRef: MatDialogRef<CouponDialogComponent>;

  constructor(
    private _billingService: BillingService,
    private _transferService: TransferService,
    public dialog: MatDialog,
    private _translateService: TranslateService
  ) {
    this._translationSubscription = this._translateService
      .get('lm-transfers.billing.logic')
      .subscribe((res: { [key: string]: string }) => {
        this._messages = res;
      });
  }
  ngOnDestroy(): void {
    this._translationSubscription.unsubscribe();
  }

  ngOnInit() { }

  getOperation() {
    this.loading = true;
    this.data = [];
    this.totalElements = 0;
    this._billingService.getOperations(this.page, this.pageSize, this.filters).subscribe({
      next: (response) => {
        const data = response.data;
        this.loading = false;
        if (data.totalElements === 0) {
          alert(this._messages['no_result_found']);
          this.filters = {};
        } else {
          this.data = data.content || [];
          this.totalElements = data.totalElements;
          this.loading = false;
        }
      },
      error: (error) => {
        this.loading = false;
        localStorage.removeItem('token');
      }
    });
  }

  getOperationsWithoutFilters() {
    this.getOperation();
  }

  getFilteredOperations(values) {
    this.filters = values;
    this.page = 0;
    this.getOperation();
  }

  exportToExcel(values) {
    this.loadExcel = true;
    this._billingService.getExcelFile(values).subscribe({
      next: (resp) => {
        const blob = new Blob(
          [
            Utilities.base64toBlob(
              resp.excel,
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ),
          ],
          {}
        );
        FileSaver.saveAs(
          blob,
          'Report_Billing_Operations'.concat(
            Utilities.getDateActual().concat('.xls')
          )
        );
        this.loadExcel = false;
      },
      error: () => {
        this.loadExcel = false;
        alert(this._messages['error_occured_try_again']);
      }
    });
  }

  sendExcelEmail(values) {
    this.loadExcel = true;
    this._billingService.sendExcelFile(values).subscribe({
      next: () => {
        this.loadExcel = false;
        alert(this._messages['report_sent_successfully']);
      },
      error: () => {
        alert(this._messages['error_occured_try_again']);
        this.loadExcel = false;
      }
    });
  }

  getPaginatorData(event) {
    this.page = event['pageIndex'];
    this.pageSize = event['pageSize'];
    this.getOperation();
  }

  showMore() {
    this.buttonShowMore
      ? (this.stringShowMore = this._messages['see_all'])
      : (this.stringShowMore = this._messages['hide_all']);
    this.buttonShowMore = !this.buttonShowMore;
  }

  getLocalNumber(number, decimals: number = 2) {
    return new Intl.NumberFormat('es-ES', {
      maximumFractionDigits: decimals,
      minimumFractionDigits: decimals,
    }).format(number);
  }

  getIsCanceled(isCanceledLessTwentyFourHours: boolean): string {
    let isCanceledLessTwentyFourHoursText = '';
    if (isCanceledLessTwentyFourHours) {
      isCanceledLessTwentyFourHoursText = '< 24 horas';
    }
    return isCanceledLessTwentyFourHoursText;
  }

  getDateWithTimeZone(d) {
    if (d) {
      const time = new Date(d).getTime();
      const offset = new Date().getTimezoneOffset();
      return new Date(time - offset * 60000).toLocaleString();
    }
    return d;
  }

  showCouponVersion(couponInfo): void {
    this._transferService.getCouponVersion(couponInfo['couponVersionId']).subscribe(res => {
      this.couponVersion = res['data'];
      if (this.dialogRef == null) {
        this.dialogRef = this.dialog.open(CouponDialogComponent, {
          width: '400px',
          height: 'auto',
          data: {
            coupon: this.couponVersion
          }
        });

        this.dialogRef.afterClosed().subscribe(result => {
          this.dialogRef = null;
        });
      }
    });
  }

}
