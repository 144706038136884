<div class="content" *ngIf="form">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ titleForm }}
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <form action="" [formGroup]="form" (ngSubmit)="submitForm()">
      <div *ngIf="filterType === 'clientOperations' || filterType === 'clientRequests'"
        class="filters_form_group">
        <label *ngIf="filterType === 'clientRequests'" style="cursor: default;">{{'lm-client.lm-filters-client-details.date_creation' | translate}} </label>
        <label *ngIf="filterType === 'clientOperations'"style="cursor: default;">{{'lm-client.lm-filters-client-details.date_appointment' | translate}} </label>
        <div class="filter-dates">
          <mat-form-field>
            <mat-label>{{'lm-client.lm-filters-client-details.placeholders.date_form' | translate}}</mat-label>
            <input matInput [matDatepicker]="picker1" placeholder="{{'lm-client.lm-filters-client-details.placeholders.date_form' | translate}}" formControlName="dateSinceStarttime"
              name="dateSinceStarttime">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{'lm-client.lm-filters-client-details.placeholders.date_to' | translate}}</mat-label>
            <input matInput [matDatepicker]="picker2" placeholder="{{'lm-client.lm-filters-client-details.placeholders.date_to' | translate}}" formControlName="dateUntilStarttime"
              name="dateUntilStarttime">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>

        <label style="cursor: default;">{{'lm-client.lm-filters-client-details.completion_date' | translate}} </label>
        <div class="filter-dates">
          <mat-form-field>
            <mat-label>{{'lm-client.lm-filters-client-details.placeholders.date_form' | translate}}</mat-label>
            <input matInput [matDatepicker]="picker3" placeholder="{{'lm-client.lm-filters-client-details.placeholders.date_form' | translate}}" formControlName="dateSinceEndtime"
              name="dateSinceEndtime">
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{'lm-client.lm-filters-client-details.placeholders.date_to' | translate}}</mat-label>
            <input matInput [matDatepicker]="picker4" placeholder="{{'lm-client.lm-filters-client-details.placeholders.date_to' | translate}}" formControlName="dateUntilEndtime"
              name="dateUntilEndtime">
            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
            <mat-datepicker #picker4></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="filterType === 'clientRequests'" class="filters_form_group">
        <label style="cursor: default;">{{'lm-client.lm-filters-client-details.date_appointment' | translate}} </label>
        <div class="filter-dates">
          <mat-form-field>
            <mat-label>{{'lm-client.lm-filters-client-details.placeholders.date_form' | translate}}</mat-label>
            <input matInput [matDatepicker]="picker5" placeholder="{{'lm-client.lm-filters-client-details.placeholders.date_form' | translate}}" formControlName="operationDateStartTime"
              name="operationDateStartTime">
            <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
            <mat-datepicker #picker5></mat-datepicker>
          </mat-form-field>
      
          <mat-form-field>
            <mat-label>{{'lm-client.lm-filters-client-details.placeholders.date_to' | translate}}</mat-label>
            <input matInput [matDatepicker]="picker6" placeholder="{{'lm-client.lm-filters-client-details.placeholders.date_to' | translate}}" formControlName="operationDateEndTime"
              name="operationDateEndTime">
            <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
            <mat-datepicker #picker6></mat-datepicker>
          </mat-form-field>
        </div>
        
      </div>
      <div class="filters_form_group">
        <div *ngIf="filterType === 'clientOperations'">
          <mat-form-field class="idRequest">
            <mat-label>{{'lm-client.lm-filters-client-details.placeholders.operation_id' | translate}}</mat-label>
            <input matInput placeholder="{{'lm-client.lm-filters-client-details.placeholders.operation_id' | translate}}" type="text" formControlName="idType" name="idType">
          </mat-form-field>
        </div>

        <div *ngIf="filterType === 'clientRequests'">
          <mat-form-field class="idRequest">
            <mat-label>{{'lm-client.lm-filters-client-details.placeholders.request_id' | translate}}</mat-label>
            <input matInput placeholder="{{'lm-client.lm-filters-client-details.placeholders.request_id' | translate}}" type="text" formControlName="idType" name="idType">
          </mat-form-field>
        </div>
        <ng-container *ngIf="filterType === 'clientOperations' || filterType === 'clientRequests'">
          <div class="filters_form_group">
          <div class="dropdown-div">
            <ng-multiselect-dropdown class="lm-input" [placeholder]="'lm-client.lm-filters-client-details.placeholders.services' | translate" [data]="services"
              formControlName="serviceSelected" name="serviceSelected" [settings]="dropdownSettingsServices">
            </ng-multiselect-dropdown>
          </div>
          <div>
            <mat-form-field class="mat-province">
              <mat-label>{{'lm-client.lm-filters-client-details.province' | translate}}</mat-label>
              <mat-select formControlName="provinceSelected" name="provinceSelected">
                <mat-option value="">{{'lm-client.lm-filters-client-details.all' | translate}}</mat-option>
                <mat-option *ngFor="let province of provinces" [value]="province.zipCode">{{province.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="filters_form_group">
          <div class="dropdown-div">
            <ng-multiselect-dropdown class="lm-input" [placeholder]="'lm-client.lm-filters-client-details.placeholders.states' | translate" [data]="statusFilters"
              formControlName="stateSelected" name="stateSelected" [settings]="dropdownSettingsStates">
            </ng-multiselect-dropdown>
          </div>
          <div>
            <div class="dropdown-div">
              <ng-multiselect-dropdown class="lm-input" [placeholder]="'lm-client.lm-filters-client-details.placeholders.campaigns' | translate" [data]="campaigns"
                formControlName="campaignSelected" name="campaignSelected" [settings]="dropdownSettingCampaigns">
              </ng-multiselect-dropdown>
            </div>
          </div>
        </div>
        <div class="filters_form_group">
          <div class="dropdown-div">
            <mat-form-field>
              <mat-label>{{'lm-client.lm-filters-client-details.placeholders.couponCode' | translate}}</mat-label>
              <input matInput [placeholder]="'lm-client.lm-filters-client-details.placeholders.couponCode' | translate" type="text" formControlName="couponCode" name="couponCode">
            </mat-form-field>
          </div>
        </div>
        </ng-container>
      </div>
      <div class="button-row">
        <button mat-raised-button [disabled]="loading" color="primary" type="submit" class="button_submit">
          <mat-icon>{{iconButtonSent}}</mat-icon> {{textButtonSent}}
        </button>
        <button mat-raised-button [disabled]="loading" type="reset" (click)="resetFilters()">{{'lm-client.lm-filters-client-details.clean' | translate}}</button>
      </div>
    </form>
  </mat-expansion-panel>
</div>
<mat-progress-bar *ngIf="loading" mode="indeterminate" value="140"></mat-progress-bar>