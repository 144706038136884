import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MaintenanceService } from '../../../core/services/mant.service';
import { Utilities } from '../../../../app/shared/utilities/utilities';
import { get as _get } from 'lodash';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../../core/services/auth.service';

@Component({
  selector: 'app-lm-resetpassword',
  templateUrl: './lm-resetpassword.component.html',
  styleUrls: ['./lm-resetpassword.component.scss'],
})
export class LmResetpasswordComponent implements OnInit, OnDestroy {
  _translateSubscription: Subscription;
  _messages: { [key: string]: string };
  form: FormGroup;
  message: string;
  isError: boolean = false;
  isDisabled: boolean = false;
  isUserLeroy: boolean;
  constructor(
    private _mant: MaintenanceService,
    private _router: Router,
    private _translateService: TranslateService,
    private _auth: AuthenticationService
  ) {
    this._translateSubscription = this._translateService
      .get('lm-resetpassword.logic')
      .subscribe((data: { [key: string]: string }) => {
        this._messages = data;
      });
  }
  ngOnDestroy(): void {
    this._translateSubscription.unsubscribe();
  }
  ngOnInit() {
    this.form = new FormGroup({
      username: new FormControl(null),
      password: new FormControl(null),
    });
    this.isUserLeroy = Utilities.isUserHogami();

    this._auth.isNewTokenExpired();
  }

  reset(user) {
    this.isDisabled = true;
    this.message = '';

    if (
      !this.isUserLeroy &&
      (user.username.toString().toLowerCase().endsWith('@leroymerlin.es') ||
        user.username.toString().toLowerCase().endsWith('@everis'))
    ) {
      this.isDisabled = false;
      this.isError = false;
      return (this.message = this._messages['operation_cannot_be_performed']);
    }

    this._mant.resetPassword(user.username, Utilities.encodeBase64(user.password)).subscribe({
      next: () => {
        this.message =
          this._messages['user_password_has_been_reset'] +
          user.username +
          this._messages['the_new_password_is'] +
          user.password;
        this.isError = false;
        this.isDisabled = false;
      },
      error: (error) => {
        this.isDisabled = false;
        this.isError = true;
        if (error.error.code === 'SIDS-404-1') {
          this.message = this._messages['user_not_found'];
        } else {
          this.message =
            this._messages['error_please_try_again'];
        }
      }
    });
  }

  goBack() {
    this._router.navigate(['/home']);
  }
}
