import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";
import { ChangeStatus, IRatingsFilters } from '../models/lm-professional.model';

@Injectable({
  providedIn: 'root'
})
export class ProfessionalService {

  private BASE_URL = environment.apiHosts;

  constructor(private _http: HttpClient) { }

  getProfessionals(page: number = 0, pageSize: number = 0, payload: any = {}): Observable<any> {
    const head = new HttpHeaders({ });
    return this._http.post<any>(
      `${this.BASE_URL}/backoffice-server/api/v1/professionals/filtered?page=${page}&size=${pageSize}`,
      payload,
      { headers: head },
    );
  }

  getRatingAverage(proffesionalId: number) {
    const head = new HttpHeaders(
      { }
    );
    return this._http.get(`${environment.apiHosts}/backoffice-server/api/v1/ratings/${proffesionalId}/average`,
      { headers: head });
  }

  getRatings(proffesionalId: number, page: number) {
    const head = new HttpHeaders(
      {  }
    );
    return this._http.get(`${environment.apiHosts}/backoffice-server/api/v1/ratings/${proffesionalId}?size=5&page=${page}&sort=createDate,desc`,
      { headers: head });
  }


	getProfessionalById(professionalId: number): Promise<any>   {
		const head = new HttpHeaders(
			{ }
		);
		return this._http.get( `${environment.apiHosts}/backoffice-server/api/v1/professionals/${professionalId}`,
			{ headers: head } ).toPromise();
	}

  addProfessionalCampaig(payload: any): Observable<any> {
    const head = new HttpHeaders( { } );
		return this._http.post( `${environment.apiHosts}/backoffice-server/api/v1/professionals/campaign`,
		payload, { headers: head } );
  }

  deleteProfessionalCampaig(payload: any): Observable<any> {
    const head = new HttpHeaders( { } );
    const httpOptions = {
      headers: head,
      body: payload
  };
		return this._http.delete( `${environment.apiHosts}/backoffice-server/api/v1/professionals/campaign`,
		httpOptions );
  }

  getProfessionalDocuments(professionalId: number): Promise<any>   {
		const head = new HttpHeaders(
			{ }
		);
		return this._http.get( `${environment.apiHosts}/backoffice-server/api/v1/documents/professional/${professionalId}`,
			{ headers: head } ).toPromise();
	}

  getProfessionalHistoricalRatings(ratingFilters: IRatingsFilters){
    const head = new HttpHeaders({});
    return this._http.post<any>(
      `${this.BASE_URL}/backoffice-server/api/v1/ratings/average/historical`,
      ratingFilters,
      { headers: head },
    );
  }

  getProfessionalStatusAllowedByCode(codeStatus: string) {
    const head = new HttpHeaders({'Accept-Language': 'es-ES'});
    return this._http.get(`${environment.apiHosts}/backoffice-server/api/v1/professionals/status-allowed?codeStatus=${codeStatus}`,
      { headers: head });
  }

  getProfessionalStatusHistories(professionalId: number, page: number = 0, pageSize: number = 0) {
    const head = new HttpHeaders({'Accept-Language': 'es-ES'});
    return this._http.get(`${environment.apiHosts}/backoffice-server/api/v1/professionals/${professionalId}/status-history?page=${page}&size=${pageSize}`,
      { headers: head });
  }

  changeProfessionalStatus(changeStatus: ChangeStatus): Observable<any>   {
    const head = new HttpHeaders({'Accept-Language': 'es-ES'});
    return this._http.post( `${environment.apiHosts}/backoffice-server/api/v1/professionals/status-change`,
		changeStatus, { headers: head } );
  }

  getAllowedOperationByStatus(codeStatus): Observable<any> {
    const head = new HttpHeaders({'Accept-Language': 'es-ES'});
    return this._http.get(`${environment.apiHosts}/backoffice-server/api/v1/professionals/permitted-and-forbidden-status/${codeStatus}`,
      { headers: head });
  }

  getProfessionalHierarchyPrice(professionalId: number, offeredServiceId: number): Promise<any> {
    const head = new HttpHeaders({'Accept-Language': 'es-ES'});
    return this._http.get(`${environment.apiHosts}/backoffice-server/api/v1/hierarchy-price?professionalId=${professionalId}&offeredServiceId=${offeredServiceId}`,
      { headers: head }).toPromise();
  }

  updateProfessionalHierarchyPrice(levelCostId, payload: any = {}): Observable<any> {
    const head = new HttpHeaders({'Accept-Language': 'es-ES'});
    return this._http.put(`${environment.apiHosts}/backoffice-server/api/v1/level-cost/${levelCostId}`,
    payload, 
    { headers: head });
  }

  retrievePersonTypes(): Observable<any> {
    const head = new HttpHeaders({'Accept-Language': 'es-ES'});
    return this._http.get(`${environment.apiHosts}/backoffice-server/api/v1/personType/`,
    { headers: head });
  }

  updateProfessionalInfo(formData: FormData, professionalId):  Observable<any> {
    const head = new HttpHeaders({'Accept-Language': 'es-ES'});
    return this._http.put(`${environment.apiHosts}/backoffice-server/api/v1/professional/${professionalId}`,
    formData, 
    { headers: head });
  }

  retrieveSepaDocumentTypes():  Observable<any> {
    const head = new HttpHeaders({'Accept-Language': 'es-ES'});
    return this._http.get(`${environment.apiHosts}/backoffice-server/api/v1/sepa/document`,
    { headers: head });
  }

  savePaycometDocument(body: any, professionalId): Observable<any> {
    const head = new HttpHeaders({'Accept-Language': 'es-ES'});
    return this._http.post( `${environment.apiHosts}/backoffice-server/api/v1/sepa/document/professionals/${professionalId}`,
		body, { headers: head } );
  }

  retrieveLanguages(professionalId: number): Observable<any> {
    const head = new HttpHeaders({'Accept-Language': 'es-ES'});
    return this._http.get(`${environment.apiHosts}/backoffice-server/api/v1/professionals/${professionalId}/languages`,
    { headers: head });
  }

  generateTemplate(): Observable<any> {
    const head = new HttpHeaders({'Accept-Language': 'es-ES'});
    return this._http.post(`${environment.apiHosts}/backoffice-server/api/v1/professionals/registration/template`,
    { headers: head });
  }

  validateTemplate(body: FormData): Observable<any> {
    const head = new HttpHeaders({'Accept-Language': 'es-ES'});
    return this._http.post(`${environment.apiHosts}/backoffice-server/api/v1/professionals/registration/validation`,
    body, { headers: head });
  }

  registerProfessionals(body: any): Observable<any> {
    const head = new HttpHeaders({'Accept-Language': 'es-ES'});
    return this._http.post(`${environment.apiHosts}/backoffice-server/api/v2/professionals/registration`,
    body, { headers: head });
  }

  updateProfessionals(body: any): Observable<any> {
    const head = new HttpHeaders({'Accept-Language': 'es-ES'});
    return this._http.put(`${environment.apiHosts}/backoffice-server/api/v1/professionals/services/modification`,
    body, { headers: head });
  }
}
