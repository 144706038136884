import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Campaigns, Provinces, Service, StatusTransfer } from 'src/app/pages/lm-transfers/models/lm-transfers.model';
import { OperationService } from 'src/app/pages/lm-transfers/services/operation.service';
import { RequestService } from 'src/app/pages/lm-transfers/services/request.service';
import { TransferService } from 'src/app/pages/lm-transfers/services/transfers.service';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { IFilters, IFiltersDataClient, IOperationsRequestsFilters } from '../../models/lm-clients.model';

@Component({
  selector: 'app-lm-filters-client-details',
  templateUrl: './lm-filters-client-details.component.html',
  styleUrls: ['../../../utils/form-search.scss', '../../../utils/dropdown.scss', './lm-filters-client-details.component.scss']
})
export class LmFiltersClientDetailsComponent implements OnInit {

  _translateSubscription: Subscription;
  @Input() filtersData: IFiltersDataClient;
  @Input() filterType: string;
  @Input() loading: boolean = false;
  @Output() filtersApply: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;
  titleForm: string;
  textButtonSent: string;
  iconButtonSent: string;
  services: Service[] = [];
  provinces: Provinces[] = [];
  campaigns: Campaigns[] = [];
  statusFilters: StatusTransfer[] = [];
  infoFilters: IFilters;
  operationsRequestsFilters: IOperationsRequestsFilters;
  dropdownSettingsServices = {};
  dropdownSettingsStates = {};
  dropdownSettingCampaigns = {};

  constructor(
    private _operationServices: OperationService,
    private _transferService: TransferService,
    private _requestServices: RequestService,
    private _translateService: TranslateService) { }

  ngOnInit() {
    this._translateSubscription = this._translateService.get('lm-client.lm-filters-client-details').subscribe(res => {
      this.titleForm = res['title'];
      this.textButtonSent = res['search'];
      this.iconButtonSent = 'search';
    });

    if (this.filterType === "clientOperations" || this.filterType === "clientRequests") {
      this.loadServices(() => { });
      this.loadProvinces();
      if (this.filterType === "clientOperations") {
        this.loadOperationStatus();
      } else if (this.filterType === "clientRequests") {
        this.loadRequestStatus();
      }
      this.loadCampaigns();
      this.createOperationsRequestsForm();
    }

    this.loadDropdownService();
    this.loadDrodownState();
    this.loadDropdownCampaigns();
    this.sendFilters();
  }

  /**
   * Retrieve all services
   * @param callback 
   */
  loadServices(callback) {
    this._operationServices.getSearchServices().subscribe(
      async res => {
        this.services = await res.data;
        this.services.sort((a, b) => a.description.localeCompare(b.description));
        this.services.forEach(element => {
          let fullDescrition = element.description + ' - ' + element.code;
          element.fullDescrition = fullDescrition;
        });
        if (callback) { callback(); }
      }
    );
  }

  /**
   * Retrieve all provinces
   */
  loadProvinces() {
    this._operationServices.getProvinces().subscribe(
      res => {
        this.provinces = this.mergeZipCodes(res.data);
      }
    )
  }

  /**
   * Retrieve all campaigns
   */
  loadCampaigns() {
    this._transferService.getAllCampaigns().subscribe(res => {
      this.campaigns = res.data;
      this.campaigns.push({
        id: 0,
        descriptionCampaign: '',
        codeCampaign: 'WEB'
      });
      this.campaigns.sort((a, b) => a.id.toString().localeCompare(b.id.toString()));
    });
  }

  /**
   * Retrieve all operation status
   */
  loadOperationStatus() {
    this._operationServices.getOperationStatus().subscribe(
      res => {
        this.statusFilters = res.data;
      }
    );
  }

  /**
   * Retrieve all request status
   */
  loadRequestStatus() {
    this._requestServices.getRequestStatus().subscribe(
      res => {
        this.statusFilters = res.data;
      }
    );
  }

  mergeZipCodes(d = []) {
    let arr = []
    d.map(item => {
      const index = arr.findIndex(a => a.name === item.name)
      if (index === -1) {
        arr.push({ name: item.name, zipCode: "" + item.zipCode })
      } else {
        arr[index] = { name: item.name, zipCode: arr[index].zipCode + "," + item.zipCode }
      }
    })
    return arr;
  }

  /**
   * This function loads the configuration of the service dropdown.
   */
  loadDropdownService() {
    const textSelection = this._translateService.instant('lm-professional.lm-filters-professional-details.text');
    this.dropdownSettingsServices = {
      singleSelection: false,
      idField: 'id',
      textField: 'fullDescrition',
      selectAllText: textSelection.selectAllText,
      unSelectAllText: textSelection.unSelectAllText,
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  /**
   * This function loads the configuration of the states dropdown.
   */
  loadDrodownState() {
    const textSelection = this._translateService.instant('lm-professional.lm-filters-professional-details.text');
    this.dropdownSettingsStates = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: textSelection.selectAllText,
      unSelectAllText: textSelection.unSelectAllText,
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  /**
   * This function loads the configuration of the states dropdown.
   */
  loadDropdownCampaigns() {
    const textSelection = this._translateService.instant('lm-professional.lm-filters-professional-details.text');
    this.dropdownSettingCampaigns = {
      singleSelection: false,
      idField: 'id',
      textField: 'codeCampaign',
      selectAllText: textSelection.selectAllText,
      unSelectAllText: textSelection.unSelectAllText,
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
  }

  /**
   * This function processes the array of services to construct an array of integers.
   * @param arrayServices 
   * @returns 
   */
  proccessServices(arrayServices) {
    return arrayServices.map(element => element.id);
  }

  /**
   * This function processes the array of states to construct an array of integers.
   * @param arrayServices 
   * @returns 
   */
  proccessStates(arrayStates: any) {
    return arrayStates.map(element => element.id);
  }

  /**
   * This function processes the array of campaigns to construct an array of integers.
   * @param arrayCampaign 
   */
  proccessCampaigns(arrayCampaign: any) {
    return arrayCampaign.map(element => element.id);
  }

  get f() { return this.form.controls; }

  /**
   * Create operation request form
   */
  createOperationsRequestsForm(): void {
    this.form = new FormGroup({
      dateSinceStarttime: new FormControl(''),
      dateUntilStarttime: new FormControl(''),
      operationDateStartTime: new FormControl(''),
      operationDateEndTime: new FormControl(''),
      dateSinceEndtime: new FormControl(''),
      dateUntilEndtime: new FormControl(''),
      serviceSelected: new FormControl(''),
      provinceSelected: new FormControl(''),
      stateSelected: new FormControl(''),
      idType: new FormControl(''),
      campaignSelected: new FormControl(''),
      couponCode: new FormControl('')
    });
  }

  /**
   * Submit form to search
   */
  submitForm(): void {
    this.sendFilters();
  }

  sendFilters() {
    if (this.filterType === "clientOperations" || this.filterType === "clientRequests") {
      this.buildOperationsRequestsFilters();
    }
  }

  /**
   * Build the json to filter
   */
  buildOperationsRequestsFilters() {
    this.operationsRequestsFilters = {
      operationDateStartTime: (this.form.controls['operationDateStartTime'].value == null || this.form.controls['operationDateStartTime'].value == "") ? null : Utilities.getDate(this.form.controls['operationDateStartTime'].value),
      operationDateEndTime: (this.form.controls['operationDateEndTime'].value == null || this.form.controls['operationDateEndTime'].value == "") ? null : Utilities.getDateEnd(this.form.controls['operationDateEndTime'].value),
      // start time
      startDateStartTime: (this.form.controls['dateSinceStarttime'].value == null || this.form.controls['dateSinceStarttime'].value == "") ? null : Utilities.getDate(this.form.controls['dateSinceStarttime'].value),
      endDateStartTime: (this.form.controls['dateUntilStarttime'].value == null || this.form.controls['dateUntilStarttime'].value == "") ? null : Utilities.getDateEnd(this.form.controls['dateUntilStarttime'].value),
      // end time
      startDateEndTime: (this.form.controls['dateSinceEndtime'].value == null || this.form.controls['dateSinceEndtime'].value == "") ? null : Utilities.getDate(this.form.controls['dateSinceEndtime'].value),
      endDateEndTime: (this.form.controls['dateUntilEndtime'].value == null || this.form.controls['dateUntilEndtime'].value == "") ? null : Utilities.getDateEnd(this.form.controls['dateUntilEndtime'].value),

      client: this.filtersData.clientEmail,
      services: (this.form.controls['serviceSelected'].value == null || this.form.controls['serviceSelected'].value == "") ? [] : this.proccessServices(this.form.controls['serviceSelected'].value),
      province: (this.form.controls['provinceSelected'].value !== null && (Number(this.form.controls['provinceSelected'].value) || !this.form.controls['provinceSelected'].value.includes(',')) ? this.form.controls['provinceSelected'].value : "0"),
      concatenatedProvince: (this.form.controls['provinceSelected'].value !== null && (this.form.controls['provinceSelected'].value != 0 && this.form.controls['provinceSelected'].value.includes(',')) ? this.form.controls['provinceSelected'].value : null),
      jobsStatus: (this.form.controls['stateSelected'].value == null || this.form.controls['stateSelected'].value == "") ? [] : this.proccessStates(this.form.controls['stateSelected'].value),
      id: (this.form.controls['idType'].value == null || this.form.controls['idType'].value == "") ? 0 : this.form.controls['idType'].value,

      campaigns: (this.form.controls['campaignSelected'].value == null || this.form.controls['campaignSelected'].value == "") ? [] : this.proccessCampaigns(this.form.controls['campaignSelected'].value),
      couponCode: (this.form.controls['couponCode'].value == null || this.form.controls['couponCode'].value == "") ? null : this.form.controls['couponCode'].value.trim(),
    }
    this.infoFilters =
    {
      isFilterBuilt: true,
      operationsRequestsFilters: this.operationsRequestsFilters
    }

    this.filtersApply.emit(this.infoFilters);
  }

  resetFilters() {
    this.form.reset();
  }

}
