import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ChatsDialogComponent } from 'src/app/pages/lm-transfers/sections/chats-dialog/chats-dialog.component';
import { ProfessionalModalComponent } from 'src/app/pages/lm-transfers/sections/professional-dialog/professional-dialog';
import { RequestService } from 'src/app/pages/lm-transfers/services/request.service';
import { IFilters, IFiltersData, IOperationsRequestsFilters } from '../../models/lm-professional.model';
import { TransferService } from 'src/app/pages/lm-transfers/services/transfers.service';
import { CouponVersion } from 'src/app/pages/lm-transfers/models/lm-transfers.model';
import { CouponDialogComponent } from 'src/app/pages/lm-transfers/sections/coupon-dialog/coupon-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-lm-professional-requests',
  templateUrl: './lm-professional-requests.component.html',
  styleUrls: ['./lm-professional-requests.component.scss', '../../../../pages/utils/mat-table.scss']
})
export class LmProfessionalRequestsComponent implements OnInit, OnDestroy {
  _translationSubscription: Subscription;
  _messages: { [key: string]: string };
  data: any = []
  page: number = 0;
  totalElements: number = 0;
  pageSize: number = 10;
  loading: boolean = false;
  filterType: string = "professionalRequests";
  filters: IFilters = {};
  operationsRequestsFilters: IOperationsRequestsFilters;
  displayedColumns: string[] = ['id', 'requestStatus', 'offeredService', 'clientDetails', 'createDate', 'operationDate', 'endDate',
    'professionalDetails', 'requestAddress', 'campaigns', 'storeName', 'comercialId', 'fill', 'actionss'];
  @Input() filtersData: IFiltersData;

  couponVersion: CouponVersion;
  dialogRef: MatDialogRef<CouponDialogComponent>;

  constructor(private _requestService: RequestService,
    public dialog: MatDialog,
    private _translateService: TranslateService,
    private _transferService: TransferService) {
    this._translationSubscription = this._translateService
      .get('lm-professional.lm-professional-requests.logic')
      .subscribe((res: { [key: string]: string }) => {
        this._messages = res;
      });
  }
  ngOnDestroy(): void {
    this._translationSubscription.unsubscribe();
  }
  ngOnInit() {
    if (this.filters !== null && this.filters.isFilterBuilt == true) {
      this.getRequests();
    }
  }

  getRequests() {
    this.operationsRequestsFilters = this.filters.operationsRequestsFilters;
    this.loading = true;
    this.data = [];
    this.totalElements = 0;

    this._requestService.getRequests(this.page, this.pageSize, this.operationsRequestsFilters).subscribe({
      next: (response) => {
        this.data = response.data.content;
        this.totalElements = response.data.totalElements;

        if (this.totalElements === 0) {
          alert(this._messages['no_result_found']);
        }
      },
      error: (err) => {
        console.error(err);
      }
    }).add(() => {
      this.loading = false;
    });
  }

  getRequestsFilters() {
    this.getRequests();
  }

  getFiltersToRequests(filtersApply) {
    this.filters = filtersApply;
    this.page = 0;
    this.getRequests();
  }

  getPaginatorData(event) {
    this.page = event['pageIndex'];
    this.pageSize = event['pageSize'];
    this.getRequests();
  }


  getDateWithTimeZone(d) {
    if (d) {
      const time = new Date(d).getTime()
      const offset = new Date().getTimezoneOffset();
      return new Date(time - offset * 60000).toLocaleString()
    }
    return d
  }


  isEmpty(str) {
    return (!str || str.length === 0);
  }

  //Chats
  openDialog(chatId: string, professional: string, clientDetails: string): void {
    if (professional != null) {
      if (clientDetails != null) {
        const dialogRef = this.dialog.open(ChatsDialogComponent, {
          width: '1200px',
          height: 'auto',
          maxHeight: '600px',
          data: {
            chatId: chatId,
            professional: professional['email'],
            client: clientDetails['email']
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          console.debug('The dialog was closed');
        });
      } else {
        alert(this._messages['customer_not_found']);
      }

    } else {
      alert(this._messages['professional_not_found']);
    }
  }

  //Professional detail
  openProfessionalDialog(professionalId: number): void {
    //this._router.navigate(['/professionals/'+professionalId]);
    if (professionalId != null) {
      var dialogRef = this.dialog.open(ProfessionalModalComponent, {
        width: '80%',
        height: '80%',
        maxHeight: '80%',
        data: {
          professionalId: professionalId
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        dialogRef = null;
      });
    }
  }

  showCouponVersion(couponInfo): void {
    this._transferService.getCouponVersion(couponInfo['couponVersionId']).subscribe(res => {
      this.couponVersion = res['data'];
      if (this.dialogRef == null) {
        this.dialogRef = this.dialog.open(CouponDialogComponent, {
          width: '400px',
          height: 'auto',
          data: {
            coupon: this.couponVersion
          }
        });

        this.dialogRef.afterClosed().subscribe(result => {
          this.dialogRef = null;
        });
      }
    });
  }

}
